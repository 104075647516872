import React from 'react'
import {Link} from 'react-router-dom';
import { useState } from 'react';
import { toggleSidebar } from "../features/sidebarToggleSlice";
import { useSelector, useDispatch } from "react-redux";

function SideBar() {

    const dispatch = useDispatch();
    const [activeDropdown, setActiveDropdown] = useState(null);

    const toggleDropdown = (dropdown) => {
        setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    };

    const handleToggleSidebar = () => {
        if (window.innerWidth < 1024) {
            dispatch(toggleSidebar());
        }
      };



  return (
    <>

<nav id="sidebar" className="sidebar-wrapper">
            <div className="sidebar-content">
                <div className="sidebar-brand flex items-center space-x-2">
                    <a href="/">
                        <div className="logo-container flex items-center justify-around space-x-2">
                            <img src="static/assets/images/logo-crm.png " height="" alt="" className='logo'/>
                            <span className='logo-text font-bold text-lg'>Suvarnakar CRM</span>
                        </div>
                    </a>
                </div>

                <ul className="sidebar-menu border-t border-white/10" data-simplebar style={{ height: 'calc(100% - 70px)' }}>
                    <li className="">
                        <Link to="/" onClick={handleToggleSidebar}>
                            <i className="uil uil-estate me-2"></i>Home
                        </Link>
                    </li>
                   
                    <li className="sidebar-dropdown">
                        <a href="#" onClick={() => toggleDropdown('customer')}>
                            <i className="uil uil-users-alt me-2"></i>Customer
                        </a>
                        <div className={`sidebar-submenu ${activeDropdown === 'customer' ? 'block' : ''}`}>
                            <ul>
                                <li ><Link to='/customer' onClick={handleToggleSidebar} >Add Customer</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="sidebar-dropdown">
                        <a href="#" onClick={() => toggleDropdown('messaging')}>
                        <i className="uil uil-message me-2"></i>Messaging
                        </a>
                        <div className={`sidebar-submenu ${activeDropdown === 'messaging' ? 'block' : ''}`}>
                            <ul>
                                {/* <li><Link to='/new-order-templates'>New Order Templates</Link></li> */}
                                {/* <li><Link to='/gold-price-templates'>Gold Price Templates</Link></li> */}
                                <li><Link to='/customer-listing' onClick={handleToggleSidebar}>All Customer</Link></li>
                                <li><Link to='/marketing-templates' onClick={handleToggleSidebar}>Message Template</Link></li>
                                <li><Link to='/batch-history' onClick={handleToggleSidebar}>Message History</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="sidebar-dropdown">
                        <a href="#" onClick={() => toggleDropdown('masters')}>
                            <i className="uil uil-browser me-2"></i>Masters
                        </a>
                        <div className={`sidebar-submenu ${activeDropdown === 'masters' ? 'block' : ''}`}>
                            <ul>
                                <li><Link to='/customer-type' onClick={handleToggleSidebar}>Customer Type</Link></li>
                                {/* <li><Link to='/message-history'>Message History</Link></li> */}
                                <li><Link to='/connect-to-whatsapp' onClick={handleToggleSidebar}>Connect To Whatsapp</Link></li>
                            </ul>
                        </div>
                        
                       
                    </li>
                </ul>
            </div>
        </nav>
    </>
  )
}

export default SideBar
